body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid-item {
    margin: 8px 8px 0 0;
}

.stretch-cell {
    width: 100% !important;
    height: 100% !important;
}

.full-width {
    width: 100% !important;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

.clickable:hover {
    background-color: #eeeeee !important;
    opacity: 0.8;
    cursor: pointer;
    transition: 0.4s;
}

.clickable {
    background-color: transparent;
    transition: 0.4s;
}

.clickable:active {
    opacity: 0.5;
    transition: 0.4s;
}

.center {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.center-column {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rounded-image-container {
    background-color: #eee;
    overflow: hidden;
}

.rounded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.spread-horizontally {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.three-dot {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fade-in {
    animation: fade-in 2s backwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@media (min-width: 768px) {
    .show-mobil {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .show-desktop {
        display: none !important;
    }
}
